import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  // {
  //   path: '/hello',
  //   name: 'Hello',
  //   component: Hello
  // },
  {
    path: '/login',
    name: 'Login',
    component: () => import( '../components/Login.vue')
  },
  {
    path: '/mykeys',
    name: 'Mykeys',
    component: () => import( '../components/Mykeys.vue')
  },
  {
    path: '/keyshare',
    name: 'KeyShare',
    component: () => import( '../components/KeyShare.vue')
  },
  {
    path: '/keyopt',
    name: 'KeyOpt',
    component: () => import( '../components/KeyOpt.vue')
  },
  {
    path: '/keysetting',
    name: 'KeySetting',
    component: () => import( '../components/KeySetting.vue')
  },
  {
    path: '/keyoptdetail',
    name: 'KeyOptDetail',
    component: () => import( '../components/KeyOptDetail.vue')
  },
  {
    path: '/keysharedetail',
    name: 'KeyShareDetail',
    component: () => import( '../components/KeyShareDetail.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
