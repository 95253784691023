import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-702bf459"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  id: "app"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");
  const _component_van_tabbar_item = _resolveComponent("van-tabbar-item");
  const _component_van_tabbar = _resolveComponent("van-tabbar");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_router_view), _createVNode(_component_van_tabbar, {
    "active-color": "#000",
    "inactive-color": "#000"
  }, {
    default: _withCtx(() => [_createVNode(_component_van_tabbar_item, null, {
      default: _withCtx(() => [_createTextVNode("kr.bpmaster.com")]),
      _: 1
    })]),
    _: 1
  })]);
}