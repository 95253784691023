// 封装axios
import axios from 'axios'
import store from '../store/index';
import router from '../router/index'
import { showToast, showFailToast } from 'vant';
import 'vant/es/toast/style';

const instance = axios.create({
    // baseURL: 'https://kr.qlkzf.com/api/',
    // baseURL: 'https://kr.bpmaster.com/api/',
    // baseURL: 'http://127.0.0.1:8000/api/',
    baseURL: '/api/',
    // baseURL: 'http://192.168.0.106:8000/api/',
    
    timeout: 30000
})

instance.interceptors.request.use(
    config => {
        // config.headers.Authorization = '1111111'
        // console.log(store.state.authTokens.access)
      if (store.state.authTokens.access) {  // 判断是否存在token，如果存在的话，则每个http header都加上token
        config.headers.Authorization = `Bearer ${store.state.authTokens.access}`;
      }
      return config;
    },
    err => {
      const response = err.response
      const status = response.status
      // console.log('error-response=', response)
      // console.log('res-statu=', status)
      if (status === 400 || status === 401) {
        // 判断状态码是400 跳转到登录，根据需要自己添加其他的判断处理
        router.replace({ path: '/login' })
      }
      return Promise.reject(err);
    });




instance.interceptors.response.use(response => {
    return response.data;
}, err => {
  const response = err.response
  const status = response.status
  // console.log('error-response=', response)
  // console.log('res-statu=', status)
  if (status === 400 || status === 401) {
    // 判断状态码是400 跳转到登录，根据需要自己添加其他的判断处理
    showFailToast('Please login')
    router.replace({ path: '/login' })
  } else {
    showFailToast('Server error now, Please try again')
  }
  return Promise.reject(err);
});


export default instance;
