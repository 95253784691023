import { createStore } from 'vuex'

export default createStore({
  state: {
    authTokens: 
    {
      'refresh':localStorage.getItem("authTokenRefresh"), 
      'access':localStorage.getItem("authTokenAccess")
    }
     || {'refresh':null,'access':null},
    selKeys: []
  },
  mutations: {
    setToken(state, newAuthTokens) {
      state.authTokens = newAuthTokens
      localStorage.setItem("authTokenRefresh",newAuthTokens['refresh']);
      localStorage.setItem("authTokenAccess",newAuthTokens['access']);
    },
    delToken (state) {
        state.authTokens = {'refresh':null, 'access':null};
        localStorage.removeItem("authTokenRefresh")
        localStorage.removeItem("authTokenAccess")
    },
    setSelKeys(state, keys) {
      state.selKeys = keys
    },
    delSelKeys(state) {
      state.selKeys = []
    },
  },
  actions: {
  },
  modules: {
  }
})

