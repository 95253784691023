// import { createApp } from 'vue'
// import App from './App.vue'

// createApp(App).mount('#app')

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './utils/http-server'
// import 'amfe-flexible/index.min.js'


import { Locale } from 'vant';
import enUS from 'vant/es/locale/lang/en-US';
Locale.use('en-US', enUS);

const app = createApp(App)
//全局配置
app.config.globalProperties.$axios=axios;

app.use(store)
app.use(router)

app.mount('#app')